import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef } from "@angular/core"
import { Faceoff, IModalListContent, IReportError, IReportErrorWithNull, IReportMistake, Playlist, VideToPlay } from "src/app/interface/interface"
import { FormatTimePipe } from "src/app/pipes/format-time.pipe"
import { MISTAKE, sharedModalContent } from "src/app/shared"

@Component({
	selector: "app-faceoffs",
	templateUrl: "./faceoffs.component.html",
	styleUrls: ["./faceoffs.component.scss"],
	providers: [FormatTimePipe],
})
export class FaceoffsComponent implements OnInit {
	@Output() saveToPlaylist = new EventEmitter<Faceoff>()
	@Output() playFaceOffs = new EventEmitter<VideToPlay[]>()
	@Output() setSelected = new EventEmitter<VideToPlay[]>()
	@Output() reportMistake = new EventEmitter<IReportMistake>()
	@Input() faceoffs: Faceoff[] = []
	@Input() matchId: string = ""
	@Input() current_video: number | undefined

	set_video: VideToPlay[] = []
	selected_faceoffs: Faceoff[] = []

	open_popup: boolean = false
	addClipToPlaylist: boolean = true
	activeFaceoff: Faceoff
	reportType: IReportErrorWithNull = null

	showModal: boolean = false
	modalContent = sharedModalContent

	constructor(private cd: ChangeDetectorRef, private timePipe: FormatTimePipe) {}

	ngOnInit(): void {
		this.faceoffs.sort((a, b) => a.videoTime - b.videoTime)
		this.faceoffs.forEach((faceoff) => (faceoff.gameState = faceoff.gameState.replace(/:/g, "/")))

		this.setSaved()
		this.setSelectedVideo()
		this.cd.detectChanges()
		console.log("MatchID: ", this.matchId)
	}

	selectFaceoffs(faceoff: Faceoff): void {
		if (this.selected_faceoffs.length != 0) {
			let vasDeleted = false
			this.selected_faceoffs.forEach((item, index) => {
				if (item.time == faceoff.time) {
					this.selected_faceoffs.splice(index, 1)
					vasDeleted = true
					return
				}
			})

			if (this.set_video.length != 0) {
				this.set_video.forEach((video) => {
					if (video.videoTime + 5 == faceoff.videoTime) {
						video.selected = false
						return
					}
				})
			}

			if (!vasDeleted) {
				this.selected_faceoffs.push(faceoff)
				this.set_video.forEach((video) => {
					if (video.videoTime + 5 == faceoff.videoTime) {
						video.selected = true
						return
					}
				})
			}
		} else {
			this.selected_faceoffs.push(faceoff)
			if (this.set_video.length != 0) {
				this.set_video.forEach((video) => {
					if (video.videoTime + 5 == faceoff.videoTime) {
						video.selected = true
						return
					}
				})
			}
		}
		this.setSelected.emit(this.set_video)
	}

	isActive(faceoff: Faceoff): boolean {
		let isIn = false
		this.selected_faceoffs.forEach((item, index) => {
			if (item.time == faceoff.time) {
				isIn = true
				return
			}
		})
		return isIn
	}

	isVideoActive = (index: number): boolean => this.current_video !== undefined && this.current_video === index

	setSaved() {
		let saved: Playlist[] = JSON.parse(sessionStorage.getItem("playList") || "")
		this.faceoffs.forEach((faceoff) => {
			faceoff.saved = false
		})
		console.log("Saved")
		if (saved) {
			saved.forEach((item) => {
				if (item.event == "faceOff") {
					this.faceoffs.forEach((faceoff) => {
						if (faceoff.videoTime == item.videoTime && faceoff.realTime.substring(0, 10) == item.date) {
							faceoff.saved = true
							return
						}
					})
				}
			})
		}
	}

	setSelectedVideo() {
		this.faceoffs.forEach((faceoff) => {
			this.set_video.push({
				matchId: this.matchId,
				videoTime: faceoff.videoTime - 5,
				videoEndTime: faceoff.videoTime + 5,
				selected: false,
				text: `Vhazovaní ${this.timePipe.transform(faceoff.time)}`,
			})
		})
		this.setSelected.emit(this.set_video)
	}

	toggleAddToPlaylist() {
		this.addClipToPlaylist = !this.addClipToPlaylist
	}

	setReportType(type: IReportError) {
		this.reportType = type
	}

	addToPlaylist(faceoff: Faceoff) {
		if (!faceoff.saved) {
			faceoff.saved = true
			this.saveToPlaylist.emit(faceoff)
		}
	}

	playOne(faceoff: Faceoff) {
		let video: VideToPlay[] = [
			{
				matchId: this.matchId,
				videoTime: faceoff.videoTime - 5,
				videoEndTime: faceoff.videoTime + 5,
				text: `Vhazování ${this.timePipe.transform(faceoff.time)}`,
			},
		]
		this.playFaceOffs.emit(video)
	}

	toggleModal = (faceoff?: Faceoff) => {
		if (faceoff) {
			this.modalContent.additionalValue = faceoff
			this.modalContent.sections[0].options[0].checked = faceoff.saved
			this.activeFaceoff = faceoff
		}

		this.modalContent.sections[1].options.forEach((option) => (option.checked = false))
		this.showModal = !this.showModal
	}

	onModalSave = (data: IModalListContent) => {
		if (data.sections[0].options[0].checked && data.additionalValue) {
			this.addToPlaylist(data.additionalValue)
		}

		const checkedOption = data.sections[1].options.find((option) => option.checked)
		if (checkedOption) {
			const reportErrorString = checkedOption.translationKey
			const reportError: IReportError = reportErrorString === "iAmNotInClip" ? "player" : reportErrorString === "wrongEventTime" ? "time" : "other"
			this.reportMistake.emit({
				eventObject: this.activeFaceoff,
				eventName: MISTAKE.faceOff,
				error: reportError,
			})
		}

		this.toggleModal()
	}
}
