import { Injectable, isDevMode } from "@angular/core"
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http"
import { Router } from "@angular/router"
import {
	IGetVideoPlayerParams,
	IReportError,
	IReportRequestReportEventData,
	OverviewGamesData,
	OverviewType,
	VideoClip,
} from "../interface/interface"

@Injectable({
	providedIn: "root",
})
export class DefaultService {
	public token: string

	api_url: string = "https://logiqplayer.statistics.datasport.cz"

	constructor(private http: HttpClient, private router: Router) {
		if (sessionStorage.getItem("currentUser")) {
			let currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "")
			if (currentUser) {
				this.token = currentUser.access_token
			}
		}
		if (isDevMode()) {
			this.api_url = "https://logiqplayer-test-migrate.statistics.datasport.cz"
		}
	}

	getToken(post: { client_id: string; client_secret: string }) {
		console.log("1")
		const headers = new HttpHeaders({ "Content-Type": "application/json" })
		const options = { headers }

		const body = {
			grant_type: "client_credentials",
			client_id: post.client_id,
			client_secret: post.client_secret,
		}

		return this.http.post<{
			access_token: string
			expires_in: number
			token_type: string
			scope: any
		}>(this.api_url + "/api/token", body, options)
	}

	logOut() {
		this.token = ""
		sessionStorage.removeItem("currentUser")
		sessionStorage.removeItem("loaded_data")
		this.router.navigate(["/"])
	}

	getPlayerLanguage() {
		const headers = new HttpHeaders({ Authorization: "Bearer " + this.token })
		const options = { headers }

		return this.http.get<{ language: string }>(this.api_url + "/api/language", options)
	}

	getOverview(playerId: string) {
		console.log("2")
		const headers = new HttpHeaders({ Authorization: "Bearer " + this.token })
		const options = { headers }
		if (playerId && playerId != "") {
			return this.http.get<OverviewGamesData[]>(this.api_url + "/api/overview", options)
		} else {
			this.errorHandler("getOverview", "playerId")
			return
		}
	}

	getCompetetionOverview() {
		const headers = new HttpHeaders({
			Authorization: "Bearer " + this.token,
		})
		const options = { headers }

		console.log("Options", options)

		return this.http.get<OverviewType>(this.api_url + "/api/competition", options)
	}

	getMatchEvents(matchId: string) {
		const headers = new HttpHeaders({
			Authorization: "Bearer " + this.token,
		})
		const options = { headers }
		if (matchId) {
			return this.http.get<any>(this.api_url + "/api/video/" + matchId, options)
		} else {
			this.errorHandler("getMatchEvents", "matchId")
			return
		}
	}

	checkToken(token: string) {
		if (!token) {
			console.error("default-service: Token is empty/undefined!")
			alert("Bad token!")
		}
	}

	errorHandler(service: string, param: string): void {
		if (!service) {
			service = "unknow service"
		}
		if (!param) {
			param = "unknow"
		}
		console.error(service + ": " + param + " param can't be empty!")
		alert("Opps! Something went wrong.")
	}

	getVideoClipsAdmin(player_id: string) {
		const headers = new HttpHeaders({
			Authorization: "Bearer " + this.token,
		})
		const options = { headers }

		console.log("Options", options)

		return this.http.get<VideoClip>(this.api_url + "/api/admin/videoClip/player/" + player_id, options)
	}

	getVideoClipsPlayer() {
		const headers = new HttpHeaders({
			Authorization: "Bearer " + this.token,
		})
		const options = { headers }

		console.log("Options", options)

		return this.http.get<VideoClip>(this.api_url + "/api/videoClip", options)
	}

	getCurrentTeam() {
		const headers = new HttpHeaders({
			Authorization: "Bearer " + this.token,
		})
		const options = { headers }

		console.log("Options", options)

		return this.http.get<VideoClip>(this.api_url + "/api/overview/team", options)
	}

	reportClip = (reportEvent: IReportRequestReportEventData, matchId: string, reportType: IReportError) => {
		const headers = new HttpHeaders({ Authorization: "Bearer " + this.token })
		const options = { headers }

		const body = {
			matchId: matchId,
			event: reportEvent,
			error: reportType,
		}

		return this.http.post(this.api_url + "/api/wrongClip", body, options)
	}

	getVideoPlayer = ({ matchId, seekTime, text, description, videoType, videoStartTime, videoEndTime, extraControls }: IGetVideoPlayerParams) => {
		const headers = new HttpHeaders({ Authorization: "Bearer " + this.token })
		let params = {}

		if (seekTime) params = { ...params, seekTime }
		if (text) params = { ...params, text }
		if (description) params = { ...params, description }
		if (videoType) params = { ...params, videoType }
		if (videoStartTime) params = { ...params, videoStartTime }
		if (videoEndTime) params = { ...params, videoEndTime }
		if (extraControls) params = { ...params, extraControls }

		return this.http.get(this.api_url + `/api/video/${matchId}/player`, {
			headers,
			params,
			responseType: "text",
		})
	}
}
