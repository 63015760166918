<div class="modal-content modal-list-content">
  <img
    *ngIf="content.image"
    src="/assets/{{ content.image }}.svg"
    alt="{{ content.image }}"
  />
  <div
    class="modal-list-content__section"
    *ngFor="let section of content.sections"
  >
    <p class="bold">{{ section.title | translate }}</p>
    <div
      class="option flex"
      [ngClass]="{
        disabled: option.disabled
      }"
      *ngFor="let option of section.options"
    >
      <div
        class="circle medium-button"
        [ngClass]="{
          'active-circle': option.checked,
          'disabled-circle': option.disabled
        }"
        (click)="selectOption(section, option)"
      >
        <div class="inner-circle" *ngIf="option.checked"></div>
      </div>
      <p>
        {{ option.translationKey | translate }} {{ option.additionalLabel }}
      </p>
    </div>
  </div>
  <div class="modal-list-content__confirm-button">
    <div class="button big-button" (click)="buttonClick()">
      {{ content.buttonTranslationKey | translate }}
    </div>
  </div>
</div>
