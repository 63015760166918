import {
  Component,
  Input,
  Output,
  EventEmitter,
  TemplateRef,
} from "@angular/core";
import {
  IModalOption,
  IModalSection,
  Playlist,
} from "src/app/interface/interface";

@Component({
  selector: "app-modal",
  templateUrl: "./modal.component.html",
  styleUrls: ["./modal.component.scss"],
})
export class ModalComponent {
  @Output() close = new EventEmitter<boolean>();
  @Output() save = new EventEmitter<Playlist>();
  @Output() wasDeleted = new EventEmitter<boolean>();
  @Output() filtered = new EventEmitter<string[]>();
  @Input() contentTemplate!: TemplateRef<any>;
  @Input() showCloseButton: boolean = true;

  constructor() {}

  selectOption(section: IModalSection, option: IModalOption): void {
    if (option.disabled) return;

    if (section.selectMultipleOptions) {
      if (option.translationKey === "all") {
        this.selectAll(section);
        return;
      }

      option.checked = !option.checked;
      return;
    }

    section.options
      .filter(
        (sectionOption) =>
          sectionOption.translationKey !== option.translationKey
      )
      .forEach((option) => (option.checked = false));
    option.checked = !option.checked;
  }

  selectAll(section: IModalSection) {
    section.options.forEach((option) => (option.checked = true));
  }

  closeModal() {
    this.close.emit(false);
  }
}
