<app-modal *ngIf="showModal" [contentTemplate]="modalContentTemplate" (close)="toggleModal()"></app-modal>

<ng-template #modalContentTemplate>
	<app-modal-list-content [content]="modalContent" (onButtonClick)="onModalSave($event)" (close)="toggleModal()"></app-modal-list-content>
</ng-template>

<div class="container widget-item shot-assists" *ngFor="let pass of passes; index as i" [ngClass]="{ 'active-video': isVideoActive(i) }">
	<div class="flex">
		<div class="circle medium-button active-circle" [ngClass]="{ 'active-circle': isActive(pass) }" (click)="selectPass(pass)">
			<div class="inner-circle" *ngIf="isActive(pass)"></div>
		</div>
		<div class="time gap">
			{{ pass.time | formatTime }}
		</div>
		<div class="state small-text grey gap">
			{{ pass.gameState }}
		</div>
	</div>
	<div class="flex">
		<div class="hover" (click)="toggleModal(pass)">
			<div class="medium-button shift-settings"></div>
		</div>
		<div class="hover" (click)="playOne(pass)">
			<div class="medium-button play_default"></div>
		</div>
	</div>
</div>
