import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing-graphics',
  templateUrl: './landing-graphics.component.html',
  styleUrls: ['./landing-graphics.component.scss']
})
export class LandingGraphicsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
