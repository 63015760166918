import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef } from "@angular/core"
import {
	IModalListContent,
	IReportError,
	IReportErrorWithNull,
	IReportMistake,
	Participation,
	Playlist,
	VideToPlay,
} from "src/app/interface/interface"
import { FormatTimePipe } from "src/app/pipes/format-time.pipe"
import { MISTAKE, participationModalContent, sharedModalContent } from "src/app/shared"

@Component({
	selector: "app-positive-negative-participations",
	templateUrl: "./positive-negative-participations.component.html",
	styleUrls: ["./positive-negative-participations.component.scss"],
	providers: [FormatTimePipe],
})
export class PositiveNegativeParticipationsComponent implements OnInit {
	@Output() saveToPlaylist = new EventEmitter<Participation>()
	@Output() playParticipation = new EventEmitter<VideToPlay[]>()
	@Output() setSelected = new EventEmitter<VideToPlay[]>()
	@Output() reportMistake = new EventEmitter<IReportMistake>()
	@Input() participations: Participation[] = []
	@Input() matchId: string = ""
	@Input() current_video: number | undefined

	set_video: VideToPlay[] = []
	selected_participations: Participation[] = []

	open_popup: boolean = false
	addClipToPlaylist: boolean = true
	activeParticipation: Participation
	reportType: IReportErrorWithNull = null

	showModal: boolean = false
	modalContent = participationModalContent

	constructor(private cd: ChangeDetectorRef, private timePipe: FormatTimePipe) {}

	ngOnInit(): void {
		this.participations.sort((a, b) => {
			return a.videoTime - b.videoTime
		})
		this.participations.forEach((participation) => {
			participation.gameState = participation.gameState.replace(/:/g, "/")
		})

		this.setSaved()
		this.setSelectedVideo()
		this.cd.detectChanges()
		console.log("MatchID: ", this.matchId)
	}

	selectParticipations(participations: Participation): void {
		if (this.selected_participations.length != 0) {
			let wasDeleted = false
			this.selected_participations.forEach((item, index) => {
				if (item.time == participations.time) {
					this.selected_participations.splice(index, 1)
					wasDeleted = true
					return
				}
			})

			if (this.set_video.length != 0) {
				this.set_video.forEach((video) => {
					if (video.videoTime + 5 == participations.videoTime) {
						video.selected = false
						return
					}
				})
			}

			if (!wasDeleted) {
				this.selected_participations.push(participations)
				this.set_video.forEach((video) => {
					if (video.videoTime + 5 == participations.videoTime) {
						video.selected = true
						return
					}
				})
			}
		} else {
			this.selected_participations.push(participations)
			if (this.set_video.length != 0) {
				this.set_video.forEach((video) => {
					if (video.videoTime + 5 == participations.videoTime) {
						video.selected = true
						return
					}
				})
			}
		}
		this.setSelected.emit(this.set_video)
	}

	isActive(participation: Participation): boolean {
		let isIn = false
		this.selected_participations.forEach((item, index) => {
			if (item.time == participation.time) {
				isIn = true
				return
			}
		})
		return isIn
	}

	isVideoActive = (index: number): boolean => this.current_video !== undefined && this.current_video === index

	setSaved() {
		let saved: Playlist[] = JSON.parse(sessionStorage.getItem("playList") || "")
		this.participations.forEach((participation) => {
			participation.saved = false
		})
		if (saved) {
			saved.forEach((item) => {
				if (item.event == "positiveParticipation" || item.event == "negativeParticipation") {
					this.participations.forEach((participation) => {
						if (participation.videoTime == item.videoTime && participation.realTime.substring(0, 10) == item.date) {
							participation.saved = true
							return
						}
					})
				}
			})
		}
	}

	setSelectedVideo() {
		this.participations.forEach((participation) => {
			this.set_video.push({
				matchId: this.matchId,
				videoTime: participation.videoTime - 5,
				videoEndTime: participation.videoTime + 5,
				selected: false,
				text: `${participation.positive ? "Plus" : "Minus"} účast ${this.timePipe.transform(participation.time)}`,
			})
		})
		this.setSelected.emit(this.set_video)
	}

	toggleAddToPlaylist() {
		this.addClipToPlaylist = !this.addClipToPlaylist
	}

	setReportType(type: IReportError) {
		this.reportType = type
	}

	addToPlaylist(participation: Participation) {
		if (!participation.saved) {
			participation.saved = true
			this.saveToPlaylist.emit(participation)
		}
	}

	playOne(participation: Participation) {
		let video: VideToPlay[] = [
			{
				matchId: this.matchId,
				videoTime: participation.videoTime - 5,
				videoEndTime: participation.videoTime + 5,
				text: `${participation.positive ? "Plus" : "Minus"} účast ${this.timePipe.transform(participation.time)}`,
			},
		]
		this.playParticipation.emit(video)
	}

	toggleModal = (participation?: Participation) => {
		if (participation) {
			this.modalContent.additionalValue = participation
			this.modalContent.sections[0].options[0].checked = participation.saved
			this.activeParticipation = participation
		}

		this.modalContent.sections[1].options.forEach((option) => (option.checked = false))
		this.showModal = !this.showModal
	}

	onModalSave = (data: IModalListContent) => {
		if (data.sections[0].options[0].checked && data.additionalValue) {
			this.addToPlaylist(data.additionalValue)
		}

		const checkedOption = data.sections[1].options.find((option) => option.checked)
		if (checkedOption) {
			const reportErrorString = checkedOption.translationKey
			const reportError: IReportError = reportErrorString === "iAmNotInClip" ? "player" : "other"
			this.reportMistake.emit({
				eventObject: this.activeParticipation,
				eventName: MISTAKE.shift,
				error: reportError,
			})
		}

		this.toggleModal()
	}
}
