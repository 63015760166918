<app-modal
	*ngIf="showModal.info || showModal.edit || showModal.delete"
	[contentTemplate]="showModal.info ? modalInfoContentTemplate : showModal.edit ? modalEditContentTemplate : modalDeleteContentTemplate"
	[showCloseButton]="false"
	(close)="toggleInfoModal()"
></app-modal>

<ng-template #modalInfoContentTemplate>
	<app-modal-info-content [content]="infoModalContent" (close)="toggleInfoModal()"></app-modal-info-content>
</ng-template>

<ng-template #modalEditContentTemplate>
	<app-modal-edit-content [content]="editModalContent" (onSave)="saveEditModal($event)" (close)="toggleEditModal()"></app-modal-edit-content>
</ng-template>

<ng-template #modalDeleteContentTemplate>
	<app-modal-delete-content (onDelete)="delete()" (close)="toggleDeleteModal()"></app-modal-delete-content>
</ng-template>

<app-loading *ngIf="loading"></app-loading>

<div class="saved" *ngIf="!loading && requestResultState !== undefined">
	<img
		src="/assets/{{ requestResultState ? 'positive_big' : 'negative_big' }}.svg"
		alt="{{ requestResultState ? 'positive_big.svg' : 'negative_big.svg' }}"
	/>
</div>

<div class="playlist">
	<div
		class="container playlist__record widget-item"
		*ngFor="let record of playlist_filtered; index as i"
		[ngClass]="{ 'active-video': isVideoActive(i) }"
	>
		<div class="item-wrapper">
			<div class="flex info-wrapper">
				<div class="">
					<p class="grey">{{ record.date }}</p>
					<p class="text">vs. {{ record.enemyTeam }}</p>
				</div>
				<div class="right-side">
					<p class="grey">{{ "action" | translate }}</p>
					<p class="text bold">
						{{ record.name ? record.name : (record.event | translate) }}
					</p>
				</div>
			</div>
			<div class="bottom-buttons flex">
				<div class="left-side flex">
					<div class="hover" (click)="toggleInfoModal(record)">
						<div class="medium-button info-icon"></div>
					</div>
					<div class="hover" (click)="toggleEditModal(record)">
						<div class="medium-button edit-icon"></div>
					</div>
					<div class="hover" (click)="toggleDeleteModal(record)">
						<div class="medium-button delete-icon"></div>
					</div>
				</div>

				<div class="hover" (click)="playOne(record)">
					<div class="medium-button play_default"></div>
				</div>
			</div>
		</div>
	</div>
</div>
