import { Component, Input, Output, EventEmitter } from "@angular/core";
import {
  IModalOption,
  IModalSection,
  IModalInfoContent,
  Playlist,
} from "src/app/interface/interface";

@Component({
  selector: "app-modal-info-content",
  templateUrl: "./modalInfoContent.component.html",
  styleUrls: ["./modalInfoContent.component.scss"],
})
export class ModalInfoContentComponent {
  @Output() close = new EventEmitter<boolean>();
  @Output() save = new EventEmitter<Playlist>();
  @Output() wasDeleted = new EventEmitter<boolean>();
  @Output() onSaveButtonClick = new EventEmitter<IModalInfoContent>();
  @Input() content: IModalInfoContent;

  playlist: Playlist[] = [];
  active_filters: string[] = [];

  loading = false;
  sucessfull = false;
  requested = false!;

  constructor() {}

  selectAll(section: IModalSection) {
    section.options.forEach((option) => (option.checked = true));
  }

  closeModal() {
    this.close.emit(false);
  }
}
