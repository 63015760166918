<div class="container admin">
	<img src="assets/icoApplogo.svg" alt="icona-default" style="margin-bottom: 20px" />
	<select class="button big-button" name="team" id="team" [(ngModel)]="selected_team" (ngModelChange)="setTeamPlyers()">
		<option value="" disabled>Vyberte tým</option>
		<option *ngFor="let team of teams" value="{{ team.shortcut }}">{{ team.name }}</option>
	</select>
	<button class="button big-button" (click)="loadAllLinkData()">Statistiky všech</button>
	<button
		[ngClass]="players_with_data.length > 0 ? '' : 'disabled'"
		[disabled]="players_with_data.length == 0"
		class="button big-button"
		(click)="loadTeamLinkData()"
	>
		Statistiky týmu
	</button>

	<app-loading *ngIf="loading"></app-loading>
</div>

<ng-container *ngIf="players_with_data.length > 0">
	<div class="playersData">
		<ng-container *ngIf="selected_player_data_loaded">
			<div class="playersData-language">
				<button [ngClass]="language == 'en' ? 'active' : ''" class="button" (click)="setPlayerLanguage('en')">En</button>
				<button [ngClass]="language == 'cz' ? 'active' : ''" class="button" (click)="setPlayerLanguage('cz')">Cz</button>
			</div>
			<div class="playersData-playerDetail">
				<div class="player-data-holder" *ngFor="let data of selected_player_data">
					<p style="overflow: auto">
						<span class="bold"> Url: </span>
						<a href="{{ data.url }}" target="_blank" rel="noopener noreferrer">{{ data.url }}</a>
					</p>
					<p>
						<span class="bold"> Status: </span>
						<span [ngStyle]="{ color: data.active ? 'green' : 'red' }">
							{{ data.active ? "Active" : "Inactive" }}
						</span>
					</p>
					<p>
						<span class="bold"> Created at: </span>
						{{ data.createdAt | formatDate : true }}
					</p>
					<p>
						<span class="bold"> Expired at: </span>
						{{ data.expiredAt | formatDate : true }}
					</p>
					<div style="margin-top: 12px">
						<mat-accordion>
							<mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
								<mat-expansion-panel-header>
									<mat-panel-title>Visits:</mat-panel-title>
									<mat-panel-description>{{ data.visits.length }}</mat-panel-description>
								</mat-expansion-panel-header>
								<div *ngFor="let visit of data.visits" style="border-top: 1px solid gray">
									<p>Date: {{ visit.date | formatDate : true }}</p>
									<p>Ip: {{ visit.ip }}</p>
								</div>
							</mat-expansion-panel>
						</mat-accordion>
					</div>
				</div>
			</div>
		</ng-container>
		<div class="playersData-container">
			<div class="playersData-header">
				<h3 class="playersData-title">{{ selected_team }}</h3>
				<img src="/assets/teams-logos/{{ selected_team }}.png" alt="" />
			</div>
			<div class="playersData-grid">
				<ng-container *ngFor="let playerData of players_with_data">
					<div class="playersData-playerRow">
						<div>
							<h3>
								{{ playerData.player.name }}
							</h3>
							<ng-container *ngIf="playerData.linkData.length > 0">
								<div class="playersData-linkRow">
									<p class="highlight">Poslední link:</p>
									<p>{{ playerData.linkData[0].createdAt | formatDate : true }}</p>
								</div>
								<div class="playersData-linkRow">
									<p class="highlight">Poslední návštěva:</p>
									<p *ngIf="playerData.linkData[0].visits.length > 0">{{ playerData.linkData[0].visits[0].date | formatDate : true }}</p>
									<p class="red" *ngIf="playerData.linkData[0].visits.length === 0">nezobrazeno</p>
								</div>
							</ng-container>
							<p class="red" *ngIf="playerData.linkData.length === 0">Žádná data</p>
						</div>
						<div class="playersData-buttonsContainer">
							<button class="button" (click)="simulatePlayerFromGrid(playerData.player.id)">Simulate player</button>
							<button
								class="button"
								[disabled]="playerData.linkData.length === 0"
								(click)="loadPlayerDataFromGrid(playerData.player.id)"
							>
								Load player data
							</button>
						</div>
					</div>
				</ng-container>
			</div>
		</div>
	</div>
</ng-container>

<div class="statistics-modal" *ngIf="stats_modal">
	<div class="statistics-container">
		<div class="statistics-header">
			<div class="back-button" (click)="closeStats()">
				<img src="/assets/arrow-left.svg" alt="go back" />
			</div>
			<button class="button big-button" (click)="exportStatsToExcel()">Export</button>
		</div>
		<div class="statistics-tableContainer">
			<table class="statistics-table">
				<thead>
					<tr>
						<th>Team</th>
						<th>Player</th>
						<th>URL</th>
						<th>Created date</th>
						<th>Visited date</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let visit of statistics_all">
						<td>
							<div class="statistics-teamInfo">
								<img src="/assets/teams-logos/{{ visit.team.shortcut }}.png" alt="" />
								<p>{{ visit.team.shortcut }}</p>
							</div>
						</td>
						<td>
							<p>{{ visit.player.name }} {{ visit.player.surname }}</p>
						</td>
						<td>
							<a [href]="visit.link.url">{{ visit.link.url }}</a>
						</td>
						<td>
							<p>{{ visit.link.createdAt | formatDate : true }}</p>
						</td>
						<td>
							<p [ngClass]="!visit.visit ? 'danger' : ''">{{ visit.visit ? (visit.visit.date | formatDate : true) : "Nenavštíveno" }}</p>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>
