import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core"
import { DomSanitizer } from "@angular/platform-browser"
import { IIframeVideo, IModalListContent, IModalOption, VideToPlay, VideoClip } from "src/app/interface/interface"
import { DefaultService } from "src/app/services/default.service"

@Component({
	selector: "app-video",
	templateUrl: "./video.component.html",
	styleUrls: ["./video.component.scss"],
})
export class VideoComponent implements OnInit, OnChanges {
	@Output() filtered = new EventEmitter<string[]>()
	@Output() current_video = new EventEmitter<number>()
	@Input() widget = ""
	@Input() to_play: VideToPlay[]
	@Input() set_play: VideToPlay[]

	iframe_key: number = 0
	iframe_index: number = 0

	selected_filters: string[] = []
	filter = ""

	url: any

	show_video = false
	showModal = false

	modalContent: IModalListContent = {
		buttonTranslationKey: "show_selected",
		sections: [
			{
				title: "showClips",
				options: [],
				selectMultipleOptions: true,
			},
		],
	}

	constructor(private sanitizer: DomSanitizer, private defaultService: DefaultService) {}

	ngOnInit(): void {
		if (window.innerHeight < window.innerWidth) {
			console.log("Screen", window.screen.height)
		}
		this.getModalOptions()
	}

	ngOnChanges(): void {
		console.log("Set play change:", this.set_play)
		this.playVideo()
	}

	getModalOptions = () => {
		let playlist: VideoClip[] = JSON.parse(sessionStorage.getItem("playList") || "")

		this.modalContent.sections[0].options = [
			{
				translationKey: "all_by_date",
				additionalValue: this.makeBrackets(playlist.length),
				disabled: playlist.length === 0,
				isAllOption: true,
			},
			this.makeOptionFromEventKey(playlist, "shift", "shifts"),
			this.makeOptionFromEventKey(playlist, "shot", "shots"),
			this.makeOptionFromEventKey(playlist, "goal", "goals_scored"),
			this.makeOptionFromEventKey(playlist, "assist", "assists"),
			this.makeOptionFromEventKey(playlist, "pass", "passes"),
			this.makeOptionFromEventKey(playlist, "faceOff", "faceoffs"),
			this.makeOptionFromEventKey(playlist, "positiveParticipation", "positiveParticipations"),
			this.makeOptionFromEventKey(playlist, "negativeParticipation", "negativeParticipations"),
		]
	}

	makeOptionFromEventKey = (playlist: VideoClip[], eventKey: string, translationKey: string): IModalOption => {
		let filteredPlaylistByEvent = playlist.filter((item) => item.event === eventKey)
		return {
			translationKey: translationKey,
			additionalLabel: this.makeBrackets(filteredPlaylistByEvent.length),
			additionalValue: eventKey,
			disabled: filteredPlaylistByEvent.length === 0,
		}
	}

	makeBrackets = (value: string | number): string => "(".concat(value.toString()).concat(")")

	handlePlayVideo(video: VideToPlay) {
		this.defaultService
			.getVideoPlayer({
				matchId: video.matchId,
				videoStartTime: video.videoTime,
				videoEndTime: video.videoEndTime,
				seekTime: 0,
				videoType: "segment",
				text: video.text,
			})
			.subscribe((data) => {
				this.handleVideoPlayer({
					html: data,
					key: this.iframe_key++,
				})
			})
	}

	handleVideoPlayer(video: IIframeVideo) {
		const currentIframe = document.getElementById("video-file-iframe") as HTMLIFrameElement
		const iframeDocument = currentIframe.contentDocument

		if (iframeDocument) {
			iframeDocument.open()
			iframeDocument.write(video.html || "")
			iframeDocument.close()
		}
	}

	handlePagination(index: number) {
		this.iframe_index = index
		this.playVideo()
	}

	handlePlayPrev() {
		this.iframe_index -= 1
		this.playVideo()
	}

	handlePlayNext() {
		this.iframe_index += 1
		this.playVideo()
	}

	playVideo() {
		if (this.to_play.length > 0) {
			const video = this.to_play[this.iframe_index]
			console.log(video, this.set_play)

			for (const [index, item] of this.set_play.entries()) {
				if (video.videoTime === item.videoTime) {
					this.current_video.emit(index)
					break
				}
			}
			this.handlePlayVideo(video)
		}
	}

	playAll() {
		if (this.set_play) {
			this.iframe_index = 0
			this.to_play = this.set_play
			this.playVideo()
		}
	}

	playSelected() {
		if (this.set_play) {
			this.to_play = []
			this.iframe_index = 0
			this.set_play.forEach((video) => {
				if (video.selected) {
					this.to_play.push(video)
				}
			})
			this.playVideo()
		}
	}

	toggleModal = () => (this.showModal = !this.showModal)

	filterPlaylist(filtered: string[]) {
		this.toggleModal()
		this.filtered.emit(filtered)
		this.selected_filters = filtered
		console.log("Filtered", filtered)
	}

	onModalConfirm(data: IModalListContent) {
		const options = data.sections[0].options
			.filter((option) => !option.isAllOption && option.checked && !option.disabled)
			.map((option) => option.additionalValue)
		this.filterPlaylist(options)
	}
}
