<div class="video">
	<div class="video-button left" *ngIf="iframe_index > 0" (click)="handlePlayPrev()">
		<img src="/assets/arrow-circle-left.svg" alt="" />
	</div>
	<div class="video-button right" *ngIf="iframe_index < to_play.length - 1" (click)="handlePlayNext()">
		<img src="/assets/arrow-circle-right.svg" alt="" />
	</div>
	<div class="video-frame">
		<iframe id="video-file-iframe" name="video-file-iframe" title="videoplayer" width="100%" height="100%" frameborder="0" allowfullscreen></iframe>
	</div>
</div>

<div class="pagination" *ngIf="to_play.length > 0">
	<div class="pagination-item" *ngFor="let item of to_play; index as i" [ngClass]="{ active: iframe_index === i }" (click)="handlePagination(i)"></div>
</div>

<div class="button-wrapper">
	<button *ngIf="widget !== 'playlist'" class="button" (click)="playAll()">
		{{ "play_all" | translate }}
	</button>
	<button *ngIf="widget !== 'playlist'" class="button" (click)="playSelected()">
		{{ "play_selected" | translate }}
	</button>
	<button *ngIf="widget === 'playlist'" class="button" [ngClass]="{ active: selected_filters.length > 0 }" (click)="toggleModal()">
		{{ "filter" | translate }}
	</button>
</div>

<app-modal *ngIf="showModal" [contentTemplate]="modalContentTemplate" (close)="toggleModal()"></app-modal>

<ng-template #modalContentTemplate>
	<app-modal-list-content [content]="modalContent" (onButtonClick)="onModalConfirm($event)" (close)="toggleModal()"></app-modal-list-content>
</ng-template>
