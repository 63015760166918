<div class="modal-content modal-video-coach-content">
  <img
    *ngIf="content.type"
    src="/assets/{{
      content.type === 'thumbs-up'
        ? 'positive_big'
        : content.type === 'thumbs-down'
        ? 'negative_big'
        : 'neutral_big'
    }}.svg"
    alt=""
  />
  <h3>{{ content.name }}</h3>
  <ng-container *ngIf="content.time">{{
    content.time | formatTime
  }}</ng-container>
  <p class="modal-video-coach-content__match-info" *ngIf="content.match">
    {{ content.match.homeTeam.id }} - {{ content.match.awayTeam.id }},
    {{ content.match.score.home }}:{{ content.match.score.away }},
    {{ content.match.date | date : "dd.MM.YYYY" }}
  </p>
  <p class="modal-video-coach-content__desc">{{ content.description }}</p>
  <div class="button big-button" (click)="closeModal()">
    {{ "close" | translate }}
  </div>
</div>
