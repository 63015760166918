import { Component, Input, OnChanges, OnInit, EventEmitter, Output } from "@angular/core"
import { PlaylistService } from "src/app/services/playlist.service"
import { IModalEditContent, IModalInfoContent, Playlist, VideToPlay } from "src/app/interface/interface"
import { TranslatePipe } from "src/app/pipes/translate.pipe"
import { FormatTimePipe } from "src/app/pipes/format-time.pipe"

@Component({
	selector: "app-playlist",
	templateUrl: "./playlist.component.html",
	styleUrls: ["./playlist.component.scss"],
	providers: [TranslatePipe, FormatTimePipe],
})
export class PlaylistComponent implements OnInit, OnChanges {
	@Output() playPlaylist = new EventEmitter<VideToPlay[]>()
	@Output() setSelected = new EventEmitter<VideToPlay[]>()
	@Input() filtered: string[] = []
	@Input() matchId: string = ""
	@Input() current_video: number | undefined

	set_video: VideToPlay[] = []
	playlist: Playlist[] = []
	playlist_filtered: Playlist[] = []
	record: Playlist

	admin = false

	player_id = ""
	loading: boolean = false
	requestResultState: boolean | undefined = undefined

	showModal = {
		info: false,
		edit: false,
		delete: false,
	}
	infoModalContent: IModalInfoContent = {
		description: "20:00 - 20:31",
		name: "Střídání",
	}
	editModalContent: IModalEditContent = {
		header: "20:00 - 20:31",
		text: "Střídání",
	}
	activePlaylistItem: Playlist

	constructor(private playlistService: PlaylistService, private translate: TranslatePipe, private formatTime: FormatTimePipe) {}

	ngOnInit(): void {
		this.player_id = JSON.parse(sessionStorage.getItem("currentUser") || "").playerId || ""

		this.admin = JSON.parse(sessionStorage.getItem("currentUser") || "").admin || false

		this.admin ? this.getAdminPlaylist() : this.getPlaylist()
	}

	ngOnChanges(): void {
		this.checkPlaylist()
		console.log("Filtered pl:", this.filtered)
	}

	checkPlaylist() {
		this.playlist_filtered = []
		if (this.filtered.length > 0 && !this.filtered.includes("all")) {
			this.playlist.map((item) => {
				this.filtered.forEach((filter) => {
					if (item.event == filter) {
						this.playlist_filtered.push(item)
						return
					}
				})
			})
		} else {
			this.playlist_filtered = this.playlist
		}
		this.setSelectedVideo()
		console.log("Filtered playlist", this.playlist_filtered)
	}

	setSelectedVideo() {
		this.set_video = []
		this.playlist_filtered.forEach((shot) => {
			this.set_video.push({
				matchId: this.matchId,
				videoTime: shot.videoTime - 5,
				videoEndTime: shot.videoTime + 5,
				selected: false,
				text: `${shot.name ? shot.name : this.translate.transform(shot.event)} ${this.formatTime.transform(shot.time)}`,
			})
		})
		this.setSelected.emit(this.set_video)
	}

	getPlaylist(): void {
		this.playlistService.getPlaylist()?.subscribe({
			next: (recivedData) => {
				this.playlist = recivedData
				this.playlist.reverse()
				this.playlist_filtered = this.playlist
				this.getEnemyTeam()
				this.savePlaylist()
				this.checkPlaylist()
				console.log(this.playlist)
			},
			error: (err) => {
				console.error("Bad API request: pS-gP", err)
				alert("Bad API request: pS-gP")
				//this.logOut();
			},
		})
	}

	getAdminPlaylist(): void {
		this.playlistService.getAdminPlaylist(this.player_id)?.subscribe({
			next: (recivedData) => {
				this.playlist = recivedData
				this.playlist.reverse()
				this.savePlaylist()
				this.checkPlaylist()
			},
			error: (err) => {
				console.error("Bad API request: pS-gAp", err)
				alert("Bad API request: pS-gAp")
				//this.logOut();
			},
		})
	}

	savePlaylist() {
		sessionStorage.setItem("playList", JSON.stringify(this.playlist))
	}

	getEnemyTeam() {
		let player_team = JSON.parse(sessionStorage.getItem("currentUser") || "").playerTeam || ""

		this.playlist.forEach((item) => {
			let enemy = ""

			let enemy_team = item.videoId.substring(item.videoId.length - 7).split("-")
			enemy_team[0] == player_team ? (item.enemyTeam = enemy_team[1]) : (item.enemyTeam = enemy_team[0])
		})
	}

	toggleInfoModal(item?: Playlist) {
		if (item) {
			this.activePlaylistItem = item
			const start = item.start ? this.formatTime.transform(item.start) : "unknown_time"
			const end = item.end ? this.formatTime.transform(item.end) : "unknown_time"
			this.infoModalContent = {
				name: this.translate.transform(item.event),
				description: start + " - " + end,
			}
		}

		this.showModal.info = !this.showModal.info
	}

	toggleEditModal(item?: Playlist) {
		if (item) {
			this.activePlaylistItem = item
			this.editModalContent = {
				header: item.name,
				text: item.description,
			}
		}

		this.showModal.edit = !this.showModal.edit
	}

	toggleDeleteModal(item?: Playlist) {
		if (item) {
			this.activePlaylistItem = item
		}

		this.showModal.delete = !this.showModal.delete
	}

	reloadPlaylist(reload: boolean) {
		if (reload) {
			this.admin ? this.getAdminPlaylist() : this.getPlaylist()
		}
	}

	saveEditModal(data: IModalEditContent) {
		this.loading = true
		this.playlistService
			.updatePlaylist(
				this.activePlaylistItem.id,
				this.activePlaylistItem.date,
				this.activePlaylistItem.type,
				this.activePlaylistItem.start || null,
				this.activePlaylistItem.end || null,
				data.header,
				data.text
			)
			?.subscribe({
				next: () => {
					this.loading = false
					this.requestResultState = true
					this.reloadPlaylist(true)
					setTimeout(() => {
						this.requestResultState = undefined
						this.toggleEditModal()
					}, 1000)
				},
				error: (err) => {
					this.loading = false
					this.requestResultState = false
					console.error("Error while updating the playlist:", err)
					alert("Update failed, try again or contact our support")
					setTimeout(() => {
						this.requestResultState = undefined
						this.toggleEditModal()
					}, 1000)
				},
			})
	}

	delete() {
		this.loading = true
		this.playlistService.removeFromPlaylist(this.activePlaylistItem.id)?.subscribe({
			next: () => {
				this.loading = false
				this.requestResultState = true
				this.reloadPlaylist(true)
				setTimeout(() => {
					this.toggleDeleteModal()
					this.requestResultState = undefined
				}, 1000)
			},
			error: (err) => {
				this.loading = false
				this.requestResultState = false
				console.error("Error while updating the playlist:", err)
				alert("Removing failed, try again or contact our support")
				setTimeout(() => {
					this.toggleDeleteModal()
					this.requestResultState = undefined
				}, 1000)
			},
		})
	}

	playOne(item: Playlist) {
		let video: VideToPlay[] = [
			{
				matchId: this.matchId,
				videoTime: item.videoTime - 5,
				videoEndTime: item.endVideoTime + 5,
				videoId: item.videoId,
				text: `${item.name ? item.name : this.translate.transform(item.event)} ${this.formatTime.transform(item.time)}`,
			},
		]
		this.playPlaylist.emit(video)
	}

	isVideoActive = (index: number): boolean => this.current_video !== undefined && this.current_video === index
}
