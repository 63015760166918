<app-modal *ngIf="showModal" [contentTemplate]="modalContentTemplate" (close)="toggleModal()"></app-modal>

<ng-template #modalContentTemplate>
	<app-modal-list-content [content]="modalContent" (onButtonClick)="onModalSave($event)" (close)="toggleModal()"></app-modal-list-content>
</ng-template>

<div
	class="container widget-item participation"
	*ngFor="let participation of participations; index as i"
	[ngClass]="{ 'active-video': isVideoActive(i) }"
>
	<div class="flex">
		<div
			class="circle medium-button active-circle"
			[ngClass]="{ 'active-circle': isActive(participation) }"
			(click)="selectParticipations(participation)"
		>
			<div class="inner-circle" *ngIf="isActive(participation)"></div>
		</div>
		<div class="time gap">
			{{ participation.time | formatTime }}
		</div>
		<div class="state small-text grey gap">
			{{ participation.gameState }}
		</div>
		<div class="gap">
			<div class="mark" [ngClass]="participation.positive ? 'mark-green' : 'mark-red'">
				{{ participation.positive ? "+1" : "-1" }}
			</div>
		</div>
	</div>
	<div class="flex">
		<div class="hover" (click)="toggleModal(participation)">
			<div class="medium-button shift-settings"></div>
		</div>
		<div class="hover" (click)="playOne(participation)">
			<div class="medium-button play_default"></div>
		</div>
	</div>
</div>
