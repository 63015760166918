<div class="Container">
	<section class="Landing">
		<img class="hero-img" src="/assets/LandingGraphics/top_motiv@2x.png" alt="" />
		<div class="Landing-titleContainer">
			<h1>ExtraPlayer</h1>
			<h3>Oficiální mobilní aplikace Pro hráče Tipsport Extraligy</h3>
			<p>
				Videozáznamy svých střídání a herních akcí má každý přihlášený hráč dostupné krátce po odehraném utkání.
				<a download href="/assets/LandingGraphics/download-graphics.pdf">Infografika ke stažení</a>
			</p>
		</div>
		<div class="Landing-cardsContainer">
			<div class="Landing-card">
				<div class="Landing-card--imgContainer">
					<img src="/assets/LandingGraphics/motiv_1@2x.png" alt="" />
				</div>
				<div class="Landing-card--textContainer">
					<span class="type">Hráč</span>
					<h3>Jednoduchý a rychlý přístup k videím</h3>
					<p>
						Po odehraném utkání, zpravidla do jedné hodiny po jeho konci, obdrží každý hráč přihlášený klubem do své e-mailové schránky
						automaticky zprávu s odkazem do aplikace ExtraPlayer. Po otevření odkazu se hráč ihned dostane do svého individuálního
						prostředí v aplikaci - bez hesla, bez nutnosti se přihlašovat či instalovat aplikaci na telefon, tablet nebo notebook.
					</p>
				</div>
			</div>
			<div class="Landing-card">
				<div class="Landing-card--textContainer">
					<span class="type">Hráč</span>
					<h3>Intuitivní a přehledné ovládání aplikace</h3>
					<p>
						Po otevření e-mailového odkazu se hráči ve webovém prohlížeči zobrazí úvodní menu aplikace obsahující hráčova střídání a
						další herní akce z posledního odehraného utkání. Ovládání aplikace je rychlé a intuitivní, bez složité filtrace či
						vyhledávání. Hráč má přístup pouze k videozáznamům svých střídání a herních akcí.
					</p>
				</div>
				<div class="Landing-card--imgContainer">
					<img src="/assets/LandingGraphics/motiv_2@2x.png" alt="" />
				</div>
			</div>
			<div class="Landing-card">
				<div class="Landing-card--imgContainer">
					<img src="/assets/LandingGraphics/motiv_3@2x.png" alt="" />
				</div>
				<div class="Landing-card--textContainer">
					<span class="type">Hráč</span>
					<h3>Playlist a ukládání oblíbených videí</h3>
					<p>
						Kromě videa z posledního odehraného utkání si může hráč prohlédnout svá střídání a herní akce i z dalších utkání, která
						odehrál v posledních 7 dnech. Videa z utkání odehraných před 8 a více dny z aplikace automaticky expirují. Hráči si však
						mohou vybraná videa ukládat na "Playlist", doplňovat si k nim poznámky a vracet se k nim i po uplynutí jejich expirační
						lhůty.
					</p>
				</div>
			</div>
		</div>
		<div class="Landing-videocoach Landing-card--textContainer">
			<span class="type">Videokouč</span>
			<h3>Poznámky videokouče k videím pro hráče</h3>
			<p>
				Videokouč může v aplikaci Hockey Logic přidat poznámky, komentáře či značení k videím ze střídání a akcí vybraného hráče nebo
				k libovolnému úseku z videozáznamu libovolného utkání. Klip spolu s informacemi se vybranému hráči či skupině hráčů, kterým je
				chce videokouč zaslat, zobrazí v hráčské aplikaci ExtraPlayer v záložce „Poznámky videokouče“.
			</p>
		</div>
		<img class="pc-img1" src="/assets/LandingGraphics/motiv_4@2x.png" alt="" />
		<div class="Landing-videocoach Landing-card--textContainer">
			<span class="type">Videokouč</span>
			<h3>Zabezpečení a ovládání účtu klubu</h3>
			<p>
				Poznámka se zobrazí vždy jen hráčům, kterým byla určena a nemůže si ji zobrazit žádný jiný hráč, videokouč jiného týmu či jiný
				uživatel aplikací ExtraPlayer nebo Hockey Logic. Videokouč má možnost přihlašovat či odhlašovat hráče k odběru videí a
				aktualizovat jejich  e-mailové adresy v aplikaci Hockey Logic.
			</p>
		</div>
		<img class="pc-img2" src="/assets/LandingGraphics/motiv_5@2x.png" alt="" />
		<p class="Landing-disclaimer">Aplikaci ExtraPlayer poskytuje hráčům Tipsport Extraligy společnost BPA sport marketing.</p>
		<div class="Landing-logosContainer">
			<img src="/assets/LandingGraphics/bpa.svg" alt="" />
			<img src="/assets/LandingGraphics/telh.svg" alt="" />
			<img src="/assets/LandingGraphics/esports.svg" alt="" />
		</div>
	</section>
	<img class="bg-img1" src="/assets/LandingGraphics/BGshape.svg" alt="" />
	<img class="bg-img2" src="/assets/LandingGraphics/BGshape.svg" alt="" />
</div>
