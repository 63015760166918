<app-modal *ngIf="showModal" [contentTemplate]="modalContentTemplate" (close)="toggleModal()"></app-modal>

<ng-template #modalContentTemplate>
	<app-modal-list-content [content]="modalContent" (onButtonClick)="onModalSave($event)" (close)="toggleModal()"></app-modal-list-content>
</ng-template>

<div class="container widget-item faceoffs" *ngFor="let faceoff of faceoffs; index as i" [ngClass]="{ 'active-video': isVideoActive(i) }">
	<div class="flex">
		<div class="circle medium-button active-circle" [ngClass]="{ 'active-circle': isActive(faceoff) }" (click)="selectFaceoffs(faceoff)">
			<div class="inner-circle" *ngIf="isActive(faceoff)"></div>
		</div>
		<div class="time gap">
			{{ faceoff.time | formatTime }}
		</div>
		<div class="state small-text grey gap">
			{{ faceoff.gameState }}
		</div>
		<div class="event" [ngClass]="{ green: faceoff.won == true, red: faceoff.won == false }">
			{{ faceoff.won ? "+" : "-" }}
			<span class="tooltip">
				{{ (faceoff.won ? "faceOff_won" : "faceOff_lost") | translate }}<br />
				{{ faceoff.time | formatTime }}
			</span>
		</div>
	</div>
	<div class="flex">
		<div class="hover" (click)="toggleModal(faceoff)">
			<div class="medium-button shift-settings"></div>
		</div>
		<div class="hover" (click)="playOne(faceoff)">
			<div class="medium-button play_default"></div>
		</div>
	</div>
</div>
