import { Component, Input, Output, EventEmitter } from "@angular/core";
import {
  IModalEditContent,
  IModalOption,
  IModalSection,
  Playlist,
} from "src/app/interface/interface";

@Component({
  selector: "app-modal-edit-content",
  templateUrl: "./modalEditContent.component.html",
  styleUrls: ["./modalEditContent.component.scss"],
})
export class ModalEditContentComponent {
  @Output() close = new EventEmitter<boolean>();
  @Output() onSave = new EventEmitter<IModalEditContent>();
  @Input() content: IModalEditContent;

  playlist: Playlist[] = [];
  active_filters: string[] = [];

  loading = false;
  sucessfull = false;
  requested = false!;

  constructor() {}

  selectOption(section: IModalSection, option: IModalOption): void {
    if (option.disabled) return;

    if (section.selectMultipleOptions) {
      if (option.isAllOption) {
        this.selectAll(section);
        return;
      }

      option.checked = !option.checked;
      this.checkForAllOption(section);
      return;
    }

    section.options
      .filter(
        (sectionOption) =>
          sectionOption.translationKey !== option.translationKey
      )
      .forEach((option) => (option.checked = false));
    option.checked = !option.checked;
    this.checkForAllOption(section);
  }

  selectAll(section: IModalSection) {
    let allOption = section.options.find((option) => option.isAllOption);
    if (allOption?.checked) {
      section.options.forEach((option) => (option.checked = false));
      return;
    }

    section.options.forEach((option) => {
      if (option.disabled) return;
      option.checked = true;
    });
  }

  checkForAllOption(section: IModalSection) {
    let allOption = section.options.find((option) => option.isAllOption);
    if (!allOption) return;

    let uncheckedOptions = section.options.filter(
      (option) => !option.checked && !option.isAllOption && !option.disabled
    );
    allOption.checked = uncheckedOptions.length === 0;
  }

  save() {
    this.onSave.emit(this.content);
  }

  closeModal() {
    this.close.emit(false);
  }
}
