import { IModalListContent } from "../interface/interface"

export const sharedModalContent: IModalListContent = {
	buttonTranslationKey: "confirm",
	sections: [
		{
			title: "clipSettings",
			options: [
				{
					translationKey: "addClipToPlaylist",
				},
			],
		},
		{
			title: "reportMistake",
			options: [
				{
					translationKey: "iAmNotInClip",
				},
				{
					translationKey: "wrongEventTime",
				},
				{
					translationKey: "otherMistake",
				},
			],
		},
	],
}

export const participationModalContent: IModalListContent = {
	buttonTranslationKey: "confirm",
	sections: [
		{
			title: "clipSettings",
			options: [
				{
					translationKey: "addClipToPlaylist",
				},
			],
		},
		{
			title: "reportMistake",
			options: [
				{
					translationKey: "iAmNotInClip",
				},
				{
					translationKey: "otherMistake",
				},
			],
		},
	],
}

export const assistsModalContent: IModalListContent = {
	buttonTranslationKey: "confirm",
	sections: [
		{
			title: "clipSettings",
			options: [
				{
					translationKey: "addClipToPlaylist",
				},
			],
		},
	],
}

export const MISTAKE = {
	shot: "shot",
	shift: "shift",
	faceOff: "faceOff",
	pass: "pass",
}
