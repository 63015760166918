import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef } from "@angular/core"
import {
	Assist,
	Faceoff,
	Goal,
	IModalListContent,
	IReportError,
	IReportShiftMistake,
	Participation,
	Pass,
	Playlist,
	Shift,
	Shot,
	VideToPlay,
} from "src/app/interface/interface"
import { FormatTimePipe } from "src/app/pipes/format-time.pipe"

@Component({
	selector: "app-all-shifts",
	templateUrl: "./all-shifts.component.html",
	styleUrls: ["./all-shifts.component.scss"],
	providers: [FormatTimePipe],
})
export class AllShiftsComponent implements OnInit {
	@Output() saveToPlaylist = new EventEmitter<Shift>()
	@Output() reportMistake = new EventEmitter<IReportShiftMistake>()
	@Output() playShift = new EventEmitter<VideToPlay[]>()
	@Output() setSelected = new EventEmitter<VideToPlay[]>()
	@Input() matchId: string = ""
	@Input() shifts: Shift[] = []
	@Input() shots: Shot[] = []
	@Input() assists: Assist[] = []
	@Input() goals: Goal[] = []
	@Input() passes: Pass[] = []
	@Input() faceoffs: Faceoff[] = []
	@Input() participations: Participation[] = []
	@Input() current_video: number | undefined

	set_video: VideToPlay[] = []
	selected_shifts: Shift[] = []

	showModal: boolean = false
	modalContent: IModalListContent = {
		buttonTranslationKey: "confirm",
		sections: [
			{
				title: "clipSettings",
				options: [
					{
						translationKey: "addClipToPlaylist",
					},
				],
			},
			{
				title: "reportMistake",
				options: [
					{
						translationKey: "iAmNotInClip",
					},
					{
						translationKey: "wrongShiftTime",
					},
					{
						translationKey: "otherMistake",
					},
				],
			},
		],
	}

	constructor(private cd: ChangeDetectorRef, private timePipe: FormatTimePipe) {}

	ngOnInit(): void {
		this.shifts.sort((a, b) => a.start - b.start)
		this.shifts.forEach((shift) => (shift.gameState = shift.gameState.replace(/:/g, "/")))

		this.setSaved()
		this.setSelectedVideo()
		this.cd.detectChanges()
	}

	selectShift(shift: Shift): void {
		if (this.selected_shifts.length > 0) {
			let wasDeleted = false
			this.selected_shifts.forEach((item, index) => {
				if (item.start == shift.start) {
					this.selected_shifts.splice(index, 1)
					wasDeleted = true
					return
				}
			})

			if (this.set_video.length != 0) {
				this.set_video.forEach((video) => {
					if (video.videoTime + 5 == shift.videoTime) {
						video.selected = false
						return
					}
				})
			}

			if (!wasDeleted) {
				this.selected_shifts.push(shift)
				this.set_video.forEach((video) => {
					if (video.videoTime + 5 == shift.videoTime) {
						video.selected = true
						return
					}
				})
			}
		} else {
			this.selected_shifts.push(shift)
			if (this.set_video.length > 0) {
				this.set_video.forEach((video) => {
					if (video.videoTime + 5 == shift.videoTime) {
						video.selected = true
						return
					}
				})
			}
		}
		this.setSelected.emit(this.set_video)
	}

	isActive = (shift: Shift): boolean => !!this.selected_shifts.find((item) => item.start == shift.start)

	isVideoActive = (index: number): boolean => this.current_video !== undefined && this.current_video === index

	setSaved() {
		let saved: Playlist[] = JSON.parse(sessionStorage.getItem("playList") || "")
		this.shifts.forEach((shift) => {
			shift.saved = false
		})
		if (!saved) return

		saved.forEach((item) => {
			if (item.event == "shift") {
				this.shifts.forEach((shift) => {
					if (shift.videoTime == item.videoTime && shift.realTime.substring(0, 10) == item.date) {
						shift.saved = true
						return
					}
				})
			}
		})
	}

	addToPlaylist(shift: Shift) {
		if (!shift.saved) {
			shift.saved = true
			this.saveToPlaylist.emit(shift)
		}
	}

	setSelectedVideo() {
		this.shifts.forEach((shitf) => {
			this.set_video.push({
				matchId: this.matchId,
				videoTime: shitf.videoTime - 5,
				videoEndTime: shitf.videoEndTime + 5,
				text: `Střídání ${this.timePipe.transform(shitf.start)} - ${this.timePipe.transform(shitf.end)}`,
				selected: false,
			})
		})
		this.setSelected.emit(this.set_video)
	}

	playOne(shitf: Shift) {
		let video: VideToPlay[] = [
			{
				matchId: this.matchId,
				videoTime: shitf.videoTime - 5,
				videoEndTime: shitf.videoEndTime + 5,
				text: `Střídání ${this.timePipe.transform(shitf.start)} - ${this.timePipe.transform(shitf.end)}`,
			},
		]
		this.playShift.emit(video)
	}

	onModalSave = (data: IModalListContent) => {
		if (data.sections[0].options[0].checked && data.additionalValue) {
			this.addToPlaylist(data.additionalValue)
		}

		const checkedOption = data.sections[1].options.find((option) => option.checked)
		if (checkedOption) {
			const reportErrorString = checkedOption.translationKey
			const reportError: IReportError = reportErrorString === "iAmNotInClip" ? "player" : reportErrorString === "wrongShiftTime" ? "time" : "other"
			this.reportMistake.emit({
				shift: data.additionalValue,
				error: reportError,
			})
		}

		this.toggleModal()
	}

	toggleModal = (shift?: Shift) => {
		if (shift) {
			this.modalContent.additionalValue = shift
			this.modalContent.sections[0].options[0].checked = shift.saved
		}

		this.modalContent.sections[1].options.forEach((option) => (option.checked = false))
		this.showModal = !this.showModal
	}
}
