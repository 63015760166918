<app-modal *ngIf="showModal" [contentTemplate]="modalContentTemplate" (close)="toggleModal()"></app-modal>

<ng-template #modalContentTemplate>
	<app-modal-list-content [content]="modalContent" (onButtonClick)="onModalSave($event)" (close)="toggleModal()"></app-modal-list-content>
</ng-template>

<div class="container widget-item shifts" *ngFor="let shift of shifts; index as i" [ngClass]="{ 'active-video': isVideoActive(i) }">
	<div class="flex">
		<div class="circle medium-button active-circle" [ngClass]="{ 'active-circle': isActive(shift) }" (click)="selectShift(shift)">
			<div class="inner-circle" *ngIf="isActive(shift)"></div>
		</div>
		<div class="time gap">{{ shift.start | formatTime }} - {{ shift.end | formatTime }}</div>
		<div class="state small-text grey gap">
			{{ shift.gameState }}
		</div>
		<div class="actions">
			<ng-container *ngFor="let shot of shots">
				<div class="event green" *ngIf="shot.time > shift.start && shot.time <= shift.end + 1">
					S
					<span class="tooltip">
						{{ "shot" | translate }}<br />
						{{ shot.time | formatTime }}
					</span>
				</div>
			</ng-container>
			<ng-container *ngFor="let goal of goals">
				<div class="event green" *ngIf="goal.time > shift.start && goal.time <= shift.end + 1">
					G
					<span class="tooltip">
						{{ "goal_scored" | translate }}<br />
						{{ goal.time | formatTime }}
					</span>
				</div>
			</ng-container>
			<ng-container *ngFor="let assist of assists">
				<div class="event green" *ngIf="assist.time > shift.start && assist.time <= shift.end + 1">
					A
					<span class="tooltip">
						{{ "goal_assists" | translate }}<br />
						{{ assist.time | formatTime }}
					</span>
				</div>
			</ng-container>
			<ng-container *ngFor="let pass of passes">
				<div class="event green" *ngIf="pass.time > shift.start && pass.time <= shift.end + 1">
					P
					<span class="tooltip">
						{{ "pass" | translate }}<br />
						{{ pass.time | formatTime }}
					</span>
				</div>
			</ng-container>
			<ng-container *ngFor="let faceoff of faceoffs">
				<div
					class="event"
					[ngClass]="{ green: faceoff.won == true, red: faceoff.won == false }"
					*ngIf="faceoff.time >= shift.start && faceoff.time < shift.end"
				>
					V
					<span class="tooltip">
						{{ (faceoff.won ? "faceOff_won" : "faceOff_lost") | translate }}<br />
						{{ faceoff.time | formatTime }}
					</span>
				</div>
			</ng-container>
			<ng-container *ngFor="let participation of participations">
				<div
					class="event"
					[ngClass]="{
						green: participation.positive == true,
						red: participation.positive == false
					}"
					*ngIf="participation.time > shift.start && participation.time <= shift.end + 1"
				>
					{{ participation.positive ? "+1" : "-1" }}
					<span class="tooltip">
						{{ (participation.positive ? "positiveParticipation" : "negativeParticipation") | translate }}<br />
						{{ participation.time | formatTime }}
					</span>
				</div>
			</ng-container>
		</div>
	</div>
	<div class="flex">
		<div class="hover" (click)="toggleModal(shift)">
			<div class="medium-button shift-settings"></div>
		</div>
		<div class="hover" (click)="playOne(shift)">
			<div class="medium-button play_default"></div>
		</div>
	</div>
</div>
