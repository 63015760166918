<app-modal *ngIf="showModal" [contentTemplate]="modalContentTemplate" (close)="toggleModal()"></app-modal>

<ng-template #modalContentTemplate>
	<app-modal-list-content [content]="modalContent" (onButtonClick)="onModalSave($event)" (close)="toggleModal()"></app-modal-list-content>
</ng-template>

<div class="container widget-item shots" *ngFor="let shot of shots; index as i" [ngClass]="{ 'active-video': isVideoActive(i) }">
	<div class="flex">
		<div class="circle medium-button active-circle" [ngClass]="{ 'active-circle': isActive(shot) }" (click)="selectShot(shot)">
			<div class="inner-circle" *ngIf="isActive(shot)"></div>
		</div>
		<div class="time gap">
			{{ shot.time | formatTime }}
		</div>
		<div class="state small-text grey gap">
			{{ shot.gameState }}
		</div>
	</div>
	<div class="flex">
		<div class="hover" (click)="toggleModal(shot)">
			<div class="medium-button shift-settings"></div>
		</div>
		<div class="hover" (click)="playOne(shot)">
			<div class="medium-button play_default"></div>
		</div>
	</div>
</div>
