import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { loading } from "src/app/animations/animations";
import { AdminService } from "src/app/services/admin.service";
import * as XLSX from "xlsx";

import {
  OverviewType,
  OverviewTeamType,
  OverviewTeamPlayerType,
  PlayerAdminData,
} from "src/app/interface/interface";

@Component({
  selector: "app-admin",
  templateUrl: "./admin.component.html",
  styleUrls: ["./admin.component.scss"],
  animations: [loading],
})
export class AdminComponent implements OnInit {
  selected_team = "";
  teams: OverviewTeamType[] = [];

  selected_players: OverviewTeamPlayerType[] = [];
  selected_players_with_placeholders: OverviewTeamPlayerType[] = [];
  selected_playerId: string | undefined;

  selected_player_data: PlayerAdminData[] = [];
  selected_player_data_loaded = false;

  all_data: OverviewType;

  statistics_all: any[] = [];
  stats_modal: boolean = false;
  stats_type: string = "ALL";

  players_with_data: any[] = [];

  panelOpenState = false;
  loading = false;
  language = "";
  NAME_PREFIX = "#";
  player_position = ["GK", "FO", "DE"];
  players_select_divider = [
    "Brankáři -----------------",
    "Útočníci -----------------",
    "Obránci -----------------",
  ];
  skip_loop_fo = true;
  skip_loop_de = true;

  test_data: PlayerAdminData[] = [
    {
      id: "4b3403665fea6",
      url: "https://foo.bar/auth?do=login&client_id=0970752000&client_secret=4b3403665fea6",
      createdAt: "2021-11-30T09:37:23+0000",
      expiredAt: "2021-12-21T09:37:23+0000",
      active: true,
      visits: [
        {
          date: "2021-11-30T09:37:56+0000",
          ip: "127.0.0.1",
        },
        {
          date: "2021-11-30T09:37:56+0000",
          ip: "127.0.0.1",
        },
        {
          date: "2021-11-30T09:37:56+0000",
          ip: "127.0.0.1",
        },
      ],
    },
    {
      id: "4b3403665fea6",
      url: "https://foo.bar/auth?do=login&client_id=0970752000&client_secret=4b3403665fea6",
      createdAt: "2021-11-30T09:37:23+0000",
      expiredAt: "2021-12-21T09:37:23+0000",
      active: false,
      visits: [
        {
          date: "2021-11-30T09:37:56+0000",
          ip: "127.0.0.1",
        },
      ],
    },
  ];

  constructor(private router: Router, private adminService: AdminService) {
    let user = JSON.parse(sessionStorage.getItem("currentUser") || "");
    if (!user.admin) {
      this.router.navigate([""]);
    }
  }

  ngOnInit(): void {
    this.loading = true;
    this.adminService.getOverview().subscribe({
      next: (recivedData) => {
        this.all_data = recivedData;
        recivedData.teams.forEach((team) => this.teams.push(team));
        this.teams.sort((a, b) => Number(a.name.localeCompare(b.name)));
        this.loading = false;
        sessionStorage.setItem("teams", JSON.stringify(this.teams));
      },
      error: (err) => {
        this.loading = false;
        console.error("Bad API request: aS-gO", err);
        alert("Bad API request: aS-gO");
        this.logOut();
      },
    });
  }

  setTeamPlyers() {
    console.log("Selected team:", this.selected_team);
    this.skip_loop_fo = true;
    this.skip_loop_de = true;
    this.selected_playerId = undefined;
    this.selected_player_data_loaded = false;
    this.teams.forEach((team) => {
      if (team.shortcut == this.selected_team) {
        this.selected_players = team.players;

        this.selected_players.sort((a, b) =>
          a.position > b.position ? -1 : 1
        );
        this.selected_players_with_placeholders = this.selected_players;
      }
    });
    /**
     * show dividers in select players with prefix
     */
    this.loadAllPlayersData();

    this.selected_players_with_placeholders.forEach((player) => {
      player.name = this.NAME_PREFIX.concat(player.jersey)
        .concat(" ")
        .concat(player.name)
        .concat(" ")
        .concat(player.surname);

      if (this.skip_loop_fo) {
        this.addNewObjectIntoPlayers(
          this.player_position[1],
          this.players_select_divider[1]
        );
        this.skip_loop_fo = false;
      }

      if (this.skip_loop_de) {
        this.addNewObjectIntoPlayers(
          this.player_position[2],
          this.players_select_divider[2]
        );
        this.skip_loop_de = false;
      }
    });
    this.selected_players_with_placeholders.sort((a, b) => {
      if (a.position === b.position) {
        return Number(a.jersey) - Number(b.jersey);
      } else {
        return a.position > b.position ? -1 : 1;
      }
    });
    console.log(
      "selected_players",
      this.selected_players,
      "selected_players_with_placeholders",
      this.selected_players_with_placeholders
    );
    console.log("Selected_team", this.selected_team);
  }

  addNewObjectIntoPlayers(position: string, name: string): void {
    this.selected_players_with_placeholders.push({
      age: 0,
      birthday: "0",
      height: 0,
      hokejczId: 0,
      id: "0",
      jersey: "0",
      name: name,
      position: position,
      stick: "0",
      surname: "0",
      weight: 0,
      yearOfBirth: 0,
    });
  }

  //Will load all selected player tokens infos
  loadPlayerData(): void {
    this.selected_player_data_loaded = false;
    this.loading = true;

    this.adminService.getPlayerAcces(this.selected_playerId)?.subscribe({
      next: (recivedData) => {
        //Data for player
        recivedData.sort(
          (a, b) => +new Date(b.createdAt) - +new Date(a.createdAt)
        );

        this.selected_player_data = recivedData;

        this.adminService
          .getPlayerLanguage(this.selected_playerId || "")
          ?.subscribe({
            next: (data) => {
              this.language = data.language;
              console.log("language", this.language);
              this.loading = false;
              this.selected_player_data_loaded = true;
            },
            error: (err) => {
              this.loading = false;
              console.error("Bad API request: aS-gPl", err);
              alert("Bad API request: aS-gPl");
            },
          });

        console.log("Loaded-01", this.selected_player_data);
      },
      error: (err) => {
        this.loading = false;
        console.error("Bad API request: aS-gPA", err);
        alert("Bad API request: aS-gPA");
        this.logOut();
      },
    });
  }

  loadAllLinkData(): void {
    this.statistics_all = [];
    this.loading = true;
    this.stats_modal = true;
    this.stats_type = "ALL";
    this.teams.forEach((team) => {
      team.players.forEach((player) => {
        if (player.id !== "0") {
          this.adminService.getPlayerAcces(player.id)?.subscribe({
            next: (data) => {
              data.sort((a, b) => {
                return +new Date(b.createdAt) - +new Date(a.createdAt);
              });

              data.forEach((link) => {
                if (link.visits.length == 0) {
                  let obj = {
                    team: team,
                    player: player,
                    link: link,
                    visit: null,
                  };

                  this.statistics_all.push(obj);
                }
                link.visits.forEach((visit) => {
                  let obj = {
                    team: team,
                    player: player,
                    link: link,
                    visit: visit,
                  };

                  this.statistics_all.push(obj);
                });
              });
            },
            error: (err) => {
              this.loading = false;
              console.error("Bad API request: aS-gPl", err);
              alert("Bad API request: aS-gPl");
            },
          });
        }
      });
    });
    this.loading = false;
  }

  loadTeamLinkData(): void {
    this.statistics_all = [];
    this.loading = true;
    this.stats_modal = true;
    this.stats_type = this.selected_team;
    this.selected_players.forEach((player) => {
      if (player.id !== "0") {
        this.adminService.getPlayerAcces(player.id)?.subscribe({
          next: (data) => {
            data.sort((a, b) => {
              return +new Date(b.createdAt) - +new Date(a.createdAt);
            });

            data.forEach((link) => {
              let team = {
                shortcut: this.selected_team,
              };
              if (link.visits.length == 0) {
                let obj = {
                  team: team,
                  player: player,
                  link: link,
                  visit: null,
                };

                this.statistics_all.push(obj);
              }
              link.visits.forEach((visit) => {
                let obj = {
                  team: team,
                  player: player,
                  link: link,
                  visit: visit,
                };

                this.statistics_all.push(obj);
              });
            });
          },
          error: (err) => {
            this.loading = false;
            console.error("Bad API request: aS-gPl", err);
            alert("Bad API request: aS-gPl");
          },
        });
      }
    });
    this.loading = false;
  }

  closeStats(): void {
    this.statistics_all = [];
    this.stats_modal = false;
  }

  exportStatsToExcel(): void {
    let table = document.querySelector(".statistics-table");
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `ExtraPlayer-${this.stats_type}.xlsx`);
  }

  //Will load all players info
  loadAllPlayersData(): void {
    this.players_with_data = [];
    this.loading = true;
    this.selected_players.forEach((player) => {
      if (player.id !== "0") {
        this.adminService.getPlayerAcces(player.id)?.subscribe({
          next: (data) => {
            data.sort((a, b) => {
              return +new Date(b.createdAt) - +new Date(a.createdAt);
            });
            let obj = {
              player: player,
              linkData: data,
            };
            this.players_with_data.push(obj);
            this.players_with_data.sort((a, b) => {
              return b.linkData.length - a.linkData.length;
            });
            this.loading = false;
          },
          error: (err) => {
            this.loading = false;
            console.error("Bad API request: aS-gPl", err);
            alert("Bad API request: aS-gPl");
          },
        });
      }
    });
  }
  loadPlayerDataFromGrid(player_id: string): void {
    this.selectPlayer(player_id);
    this.selected_playerId = player_id;
    this.loadPlayerData();
  }
  simulatePlayerFromGrid(player_id: string): void {
    this.selectPlayer(player_id);
    this.selected_playerId = player_id;
    this.simulatePlayer();
  }

  setPlayerLanguage(language: string) {
    this.loading = true;
    this.adminService
      .setPlayerLanguage(this.selected_playerId || "", language)
      ?.subscribe({
        next: () => {
          this.language = language;
          this.loading = false;
          this.selected_player_data_loaded = true;
        },
        error: (err) => {
          this.loading = false;
          console.error("Bad API request: aS-sPl", err);
          alert("Bad API request: aS-sPl");
        },
      });
  }

  simulatePlayer(): void {
    if (this.selected_playerId) {
      let user = JSON.parse(sessionStorage.getItem("currentUser") || "");
      user.playerId = this.selected_playerId;
      user.playerTeam = this.selected_team;
      sessionStorage.setItem("currentUser", JSON.stringify(user));

      let link = window.location.origin + "/home";
      window.open(link, "_blank");
    }
  }

  selectPlayer(id: string | undefined): void {
    this.selected_player_data_loaded = false;
    console.log("ID:", id);

    if (id) {
      this.selected_players.forEach((player) => {
        if (player.id == id) {
          let user = JSON.parse(sessionStorage.getItem("currentUser") || "");
          user.name = player.name;
          sessionStorage.setItem("currentUser", JSON.stringify(user));
        }
      });
    } else {
      let user = JSON.parse(sessionStorage.getItem("currentUser") || "");
      user.name = "";
      sessionStorage.setItem("currentUser", JSON.stringify(user));
    }

    console.log("Selected players", this.selected_players);
  }

  logOut(): void {
    sessionStorage.removeItem("currentUser");
    sessionStorage.removeItem("loaded_data");
    this.router.navigate([""]);
  }
}
