<div class="modal-wrapper">
  <div class="modal">
    <img
      class="modal__close"
      src="/assets/close.svg"
      alt="close"
      (click)="closeModal()"
      *ngIf="showCloseButton"
    />
    <div class="modal__content">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
  </div>
</div>
