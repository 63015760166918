import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import {
	ICommonObject,
	Goal,
	IReportError,
	IReportErrorWithNull,
	Playlist,
	VideToPlay,
	IReportMistake,
	IModalListContent,
} from "../../interface/interface"
import { DefaultService } from "src/app/services/default.service"
import { MISTAKE, sharedModalContent } from "src/app/shared"
import { FormatTimePipe } from "src/app/pipes/format-time.pipe"

@Component({
	selector: "app-one-timer-saved",
	templateUrl: "./one-timer-saved.component.html",
	styleUrls: ["./one-timer-saved.component.scss"],
	providers: [FormatTimePipe],
})
export class OneTimerSavedComponent implements OnInit {
	@Output() saveToPlaylist = new EventEmitter<ICommonObject>()
	@Output() playOneTimersSaved = new EventEmitter<VideToPlay[]>()
	@Output() setSelected = new EventEmitter<VideToPlay[]>()
	@Output() reportMistake = new EventEmitter<IReportMistake>()
	@Input() goals: ICommonObject[] = []
	@Input() matchId: string = ""
	@Input() current_video: number | undefined

	set_video: VideToPlay[] = []
	selected_goals: ICommonObject[] = []

	open_popup: boolean = false
	addClipToPlaylist: boolean = true

	activeGoal: ICommonObject
	reportType: IReportErrorWithNull = null

	showModal: boolean = false
	modalContent = sharedModalContent

	constructor(private cd: ChangeDetectorRef, private timePipe: FormatTimePipe) {}

	ngOnInit(): void {
		this.goals.sort((a, b) => {
			return a.time - b.time
		})
		this.goals.forEach((goal) => {
			goal.gameState = goal.gameState.replace(/:/g, "/")
		})

		this.setSaved()
		this.setSelectedVideo()
		this.cd.detectChanges()
		console.log("MatchID: ", this.matchId)
	}

	selectGoal(goal: Goal): void {
		if (this.selected_goals.length != 0) {
			let vasDeleted = false
			this.selected_goals.forEach((item, index) => {
				if (item.time == goal.time) {
					this.selected_goals.splice(index, 1)
					vasDeleted = true
					return
				}
			})

			if (this.set_video.length != 0) {
				this.set_video.forEach((video) => {
					if (video.videoTime + 5 == goal.videoTime) {
						video.selected = false
						return
					}
				})
			}

			if (!vasDeleted) {
				this.selected_goals.push(goal)
				this.set_video.forEach((video) => {
					if (video.videoTime + 5 == goal.videoTime) {
						video.selected = true
						return
					}
				})
			}
		} else {
			this.selected_goals.push(goal)
			if (this.set_video.length != 0) {
				this.set_video.forEach((video) => {
					if (video.videoTime + 5 == goal.videoTime) {
						video.selected = true
						return
					}
				})
			}
		}
	}

	isActive(goal: ICommonObject): boolean {
		let isIn = false
		this.selected_goals.forEach((item, index) => {
			if (item.time == goal.time) {
				isIn = true
				return
			}
		})
		return isIn
	}

	isVideoActive = (index: number): boolean => this.current_video !== undefined && this.current_video === index

	setSaved() {
		let saved: Playlist[] = JSON.parse(sessionStorage.getItem("playList") || "")
		this.goals.forEach((goal) => {
			goal.saved = false
		})
		if (saved) {
			saved.forEach((item) => {
				if (item.event == "goalsAgainst") {
					this.goals.forEach((goal) => {
						if (goal.videoTime == item.videoTime && goal.realTime.substring(0, 10) == item.date) {
							goal.saved = true
							return
						}
					})
				}
			})
		}
	}

	setSelectedVideo() {
		this.goals.forEach((goal) => {
			this.set_video.push({
				matchId: this.matchId,
				videoTime: goal.videoTime - 5,
				videoEndTime: goal.videoTime + 5,
				selected: false,
				text: `Zákrok ${this.timePipe.transform(goal.time)}`,
			})
		})
		this.setSelected.emit(this.set_video)
	}

	toggleAddToPlaylist() {
		this.addClipToPlaylist = !this.addClipToPlaylist
	}

	setReportType(type: IReportError) {
		this.reportType = type
	}

	addToPlaylist(goal: ICommonObject) {
		if (!goal.saved) {
			goal.saved = true
			this.saveToPlaylist.emit(goal)
		}
	}

	playOne(goal: ICommonObject) {
		let video: VideToPlay[] = [
			{
				matchId: this.matchId,
				videoTime: goal.videoTime - 5,
				videoEndTime: goal.videoTime + 5,
				text: `Zákrok ${this.timePipe.transform(goal.time)}`,
			},
		]
		this.playOneTimersSaved.emit(video)
	}

	toggleModal = (goal?: ICommonObject) => {
		if (goal) {
			this.modalContent.additionalValue = goal
			this.modalContent.sections[0].options[0].checked = goal.saved
			this.activeGoal = goal
		}

		this.modalContent.sections[1].options.forEach((option) => (option.checked = false))
		this.showModal = !this.showModal
	}

	onModalSave = (data: IModalListContent) => {
		if (data.sections[0].options[0].checked && data.additionalValue) {
			this.addToPlaylist(data.additionalValue)
		}

		const checkedOption = data.sections[1].options.find((option) => option.checked)
		if (checkedOption) {
			const reportErrorString = checkedOption.translationKey
			const reportError: IReportError = reportErrorString === "iAmNotInClip" ? "player" : reportErrorString === "wrongEventTime" ? "time" : "other"
			this.reportMistake.emit({
				eventObject: this.activeGoal,
				eventName: MISTAKE.shot,
				error: reportError,
			})
		}

		this.toggleModal()
	}
}
