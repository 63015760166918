<div class="modal-content modal-delete-content">
  <img src="/assets/icoTrash.svg" alt="Odstranit" />
  <h2 class="header">{{ "really_delete" | translate }}</h2>
  <div
    class="button big-button bottom-gap widget-item red-button"
    (click)="delete()"
  >
    {{ "delete_from_playlist" | translate }}
  </div>
  <div class="button big-button grey-button" (click)="closeModal()">
    {{ "close_without_deleting" | translate }}
  </div>
</div>
