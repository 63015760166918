<div class="modal-content modal-edit-content">
  <div class="headers">
    <p class="bold">{{ "clip_name" | translate }}</p>
  </div>
  <input
    [(ngModel)]="content.header"
    class="input clip-name"
    type="text"
    maxlength="25"
  />
  <div class="headers">
    <p class="bold">{{ "note" | translate }}</p>
  </div>
  <textarea
    [(ngModel)]="content.text"
    class="input clip-text"
    name=""
    id=""
    cols="30"
    rows="10"
    maxlength="256"
  ></textarea>
  <div class="button big-button bottom-gap widget-item" (click)="save()">
    {{ "save_and_close" | translate }}
  </div>
  <div class="button big-button grey-button" (click)="closeModal()">
    {{ "close_without_saving" | translate }}
  </div>
</div>
